body {
  font: 20px "Roboto";
  margin: 5% 10% 5% 10%;
}

ol, ul {
  padding-left: 30px;
}


.header {
    font: 24px "Roboto";
    position: relative;
    z-index: 1;
    margin-top: 10px;
}

.h1, h1 {
    font-size: 3rem;
}
.h1, h1  {
    margin-bottom: 3rem;
    font-weight: 600;
    line-height: 1.2;
}
h1 {
    margin-top: 0;
    margin-bottom: 3rem;
}

h2, h3, h4, h5, h6 {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-weight: 600;

}


.main {
    padding-top: 10px;
    position: relative;
    font-size: 20px;
    width: 80%;
    margin: auto;
}

.navBar {
  position: relative;
  z-index: 10;
}

.navBarBtn {
  width: 33.33%;
}


#map {
  padding: 0% 0% 0% 0%;
  position: relative;
  width: 100%;
  height: 70%;
  z-index: 2;
}

.mapContainer {
  padding: 40px 0px 0px 0px;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.mapButtons {
  background-color: gray;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 5px 5px 5px 5px;
  z-index: 2;
}

.layerBtnContainer {
  position: relative;
  /* top: 20px; */
  right: 0px;
  padding: 5px 5px 5px 5px;
  z-index: 10;
}

.board-row:after {
  clear: both;
  content: "";
  display: table;
}

.status {
  margin-bottom: 10px;
}

.square {
  background: #fff;
  border: 2.5px solid #999;
  float: left;
  font-size: 60px;
  font-weight: bold;
  line-height: 100px;
  height: 100px;
  margin-right: -2.5px;
  margin-top: -2.5px;
  padding: 0;
  text-align: center;
  width: 100px;
}

.square:focus {
  outline: none;
}

.kbd-navigation .square:focus {
  background: #ddd;
}

.game {
  margin-top: 40px;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
}

.game-info {
  margin-left: 20px;
}

.img-circular{
    margin: 15px;
    float: left;
    width: 200px;
    height: 200px;
    background-size: cover;
    display: block;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
}
